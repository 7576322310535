<template>
    <div style="display: flex; justify-content: flex-start; gap: 1rem;">
        <InputText v-model="url" style="width: 80%;" />
        <Button icon="pi pi-check" class=" p-button-success mx-2 " label="Add & GetData" @click="buildActor()"
            :loading="loadingCrawler" />
    </div>
    <div style="display: flex; justify-content: center;" v-if="urlErrorRequired">
        <small class="p-error">
            Url is Required
        </small>
    </div>

    <div v-if="actorNoData">
        <small class="p-error">
            Api Failed Try Another one?
            <Button 
                icon="pi pi-check" 
                class=" p-button-success mx-2 " 
                label="Add & GetData" 
                @click="buildActor2()"
                :loading="loadingCrawler2" />
        </small>
    </div>
</template>

<script>
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
// axios
import axios from 'axios';


export default {
    name: 'DocumentCrawler',
    components: {
        Button,
        InputText,
    },
    emits: ['getDocumentDataApifyWebsiteContentCrawler','getDocumentDataApifySmartArticleExtractor'],
    props: [],
    data() {
        return {
            url: null,
            loadingCrawler: false,
            loadingCrawler2: false ,
            urlErrorRequired: false,
            actorNoData: false,
        };
    },
    created() { 
    },
    computed: {},
    methods: {
        async buildActor() {
            try {
                this.loadingCrawler = true;
                const response = await this.apiFySmartArticleExtractor()

                if (response.data.length > 0) {
                    this.$emit("getDocumentDataApifySmartArticleExtractor", {response : response })
                }
                else {
                    this.actorNoData = true
                }
                this.loadingCrawler = false
            }
            catch (error) {
                console.log(error);
                this.actorNoData = true
                this.loadingCrawler = false
            }
        },

        async buildActor2() {
            try {
                this.loadingCrawler2 = true
                const response = await this.apiFyWebsiteContentCrawler()
                if (response.data.length > 0) {
                    this.$emit("getDocumentDataApifyWebsiteContentCrawler", { response: response });
                }
                else {
                    console.log('error');
                }
                this.loadingCrawler2 = false
                

            } catch (error) {
                console.log(error);
                this.loadingCrawler2 = false
            }
        },

        async apiFySmartArticleExtractor() {
            const input = {
                "articleUrls": [
                    {
                        "url": this.url
                    }
                ]
            }

            const apiclient = axios.create({
                headers: {
                    'Authorization': `Bearer apify_api_dfiQdcEx59opegql4LxdjDUPMJhrch4kdUz8`,
                    'Content-Type': 'application/json',
                }
            })

            const response = await apiclient.post('https://api.apify.com/v2/acts/lukaskrivka~article-extractor-smart/run-sync-get-dataset-items', input)
            return response
        },


        async apiFyWebsiteContentCrawler() {
            const input = {
                "maxCrawlPages": 1,
                "maxResults": 1,
                "startUrls": [
                    {
                        "url": this.url
                    }
                ]
            }

            const apiclient = axios.create({
                headers: {
                    'Authorization': `Bearer apify_api_dfiQdcEx59opegql4LxdjDUPMJhrch4kdUz8`,
                    'Content-Type': 'application/json',
                }
            })
            const response = await apiclient.post('https://api.apify.com/v2/acts/apify~website-content-crawler/run-sync-get-dataset-items', input)
            return response
            // this.$emit("getDocumentData", { response: response });
        }


    }
}

</script>

<style></style>